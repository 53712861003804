import React, { useEffect, useState ,useMemo} from "react";
import {
  Box,
  TextField,
  useMediaQuery,
  MenuItem,
  Typography,
  Button,
  Tabs,
  Tab,
  styled,
  useTheme,
  Autocomplete,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useParams ,useNavigate, Link} from "react-router-dom";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import DiscountDeliveryOffer from "../../components/DiscountDeliveryOffer";
import ImgPreview from "../../components/ImgPreview";
import YellowSwitch from "../../components/YellowSwitch";
import PackageOffer from "../../components/PackageOffer";
import GetOffer from "../../components/GetOffer";
import { useDispatch, useSelector } from "react-redux";
import { fetchALLMerchants, getAllMerchants,getAllMerchantsBranches ,getAllMerchantsNameId,fetchALLBranches,fetchALLMerchantsNameId} from "../../features/merchants/merchantSlice";
import {
  fetchALLProducts,
  getAllProducts,
  fetchALLProductsByMerchants,
  getAllProductsByMerchants,
  getLoading,
  toggleActive,
} from "../../features/products/productSlice";

import {
  saveNewOffer,
  getOfferById,
  fetchALLOffers,
  updateOffer
} from "../../features/offers/offerSlice";

import {
  fetchALLOrganizations,
  getAllOrganizations,
} from "../../features/organizations/organizationSlice";

import SearchOraganisation from "../../components/SearchOraganisation";
import { setIsEditingOffer } from "../../features/offers/editSlice";
// import GetOffer from "../../components/GetOffer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



const OffersForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isEditingOffer = useSelector(setIsEditingOffer);

  //console.log("isEditingOfferisEditingOffer:",isEditingOffer)
  //console.log("paramsparamsparams:",params.id)
  const itemToEdit = useSelector(getOfferById(params.id));
  console.log("itemToEdititemToEdit:",itemToEdit)
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [delivery, setdelivery] = useState(itemToEdit?.delivery || false);
  const [vip, setVip] = useState(false);
  const [buyItems, setBuyItems] = useState([]);
  const [getItems, setGetItems] = useState([]);
  const [tabName, setTabName] = useState();

  const [switchState, setSwitchState] = useState({
    show: true,
    available: true,
    archive: true,
    bookingAvailable: true,
    loyaltyPoints: false,
    showOrg: true,
  });
  const [availableBranch, setAvailableBranch] = useState(itemToEdit?.branches);
  const [selectedFiles, setSelectedFiles] = useState(itemToEdit?.offerImage ?? []);
  const allMerchants = useSelector(getAllMerchants());
  const allMerchantsNameId = useSelector(getAllMerchantsNameId());
  console.log("allMerchantsNameId:",allMerchantsNameId)
  console.log("deliverydelivery:",delivery)
  const allOrganizations = useSelector(getAllOrganizations);
  //console.log("allMerchantsNameIdallMerchantsNameIdallMerchantsNameIdallMerchantsNameId:",allMerchantsNameId)
  console.log("allMerchantsallMerchantsallMerchants:",allMerchants)
  const allBranches =  useSelector(getAllMerchantsBranches());
  const allProducts =  useSelector(getAllProducts);
  const allProductsBymerchants =  useSelector(getAllProductsByMerchants);
  
  console.log("allBranches:",allBranches)
  
  console.log("allProductsBymerchantsallProductsBymerchants:",allProductsBymerchants)
  const suggestions = allProductsBymerchants;
  // [
  //   { name: "Apple", price: 0.99, product_id: 1 },
  //   { name: "Banana", price: 1.29, product_id: 2 },
  //   { name: "Cherry", price: 2.49, product_id: 3 },
  //   { name: "Durian", price: 5.99, product_id: 4 },
  //   { name: "Elderberry", price: 3.99, product_id: 5 },
  //   { name: "Fig", price: 1.99, product_id: 6 },
  //   { name: "Grape", price: 0.49, product_id: 7 },
  //   { name: "Honeydew", price: 2.99, product_id: 8 },
  //   { name: "Ivy gourd", price: 4.99, product_id: 9 },
  //   { name: "Jackfruit", price: 6.99, product_id: 10 },
  //   { name: "Kiwi", price: 1.79, product_id: 11 },
  //   { name: "Lemon", price: 0.69, product_id: 12 },
  //   { name: "Mango", price: 2.99, product_id: 13 },
  //   { name: "Nectarine", price: 1.49, product_id: 14 },
  //   { name: "Orange", price: 0.89, product_id: 15 },
  //   { name: "Papaya", price: 3.49, product_id: 16 },
  //   { name: "Quince", price: 4.99, product_id: 17 },
  //   { name: "Raspberry", price: 2.99, product_id: 18 },
  //   { name: "Strawberry", price: 1.99, product_id: 19 },
  //   { name: "Tangerine", price: 1.19, product_id: 20 },
  //   { name: "Ugli fruit", price: 5.99, product_id: 21 },
  //   { name: "Vanilla bean", price: 9.99, product_id: 22 },
  //   { name: "Watermelon", price: 4.99, product_id: 23 },
  //   { name: "Xigua", price: 3.99, product_id: 24 },
  //   { name: "Yellow watermelon", price: 5.99, product_id: 25 },
  //   { name: "Zucchini", price: 1.29, product_id: 26 },
  // ];

  useEffect(() => {
    dispatch(fetchALLOffers());
     dispatch(fetchALLMerchantsNameId());
    dispatch(fetchALLProducts());
    dispatch(fetchALLMerchants());

    itemToEdit?.merchant && dispatch(fetchALLBranches(itemToEdit?.merchant));
  }, [dispatch]);


  const branchHandler = (newSelectedItems) => {
    console.log("branchHandler",newSelectedItems)
    dispatch(fetchALLBranches(newSelectedItems.value));
    dispatch(fetchALLProductsByMerchants(newSelectedItems.value));
  };

  const handleDiscountItemsChange = (newSelectedItems) => {
    console.log("newSelectedItems:",newSelectedItems)
    const discountsAndOptions = newSelectedItems.map(
      ({ discount, option,Products }) => ({ discount, option,Products })
    );
     console.log(discountsAndOptions,"discountsAndOptions")
    setBuyItems(discountsAndOptions);
  };

  const handleBuyItemsChange = (newSelectedItems) => {
    console.log("handleBuyItemsChange:",newSelectedItems)
    const productIDandQnt = newSelectedItems.map(
      ({ product_id, productQnt }) => ({ "Products":product_id, productQnt  })
    );
    setBuyItems(productIDandQnt);
  };
  const handleGetItemsChange = (newSelectedItems) => {
    console.log("handleGetItemsChange:",newSelectedItems)
    const productIDandQnt = newSelectedItems.map(
      ({ product_id, productQnt }) => ({ "Products":product_id, productQnt  })
    );
    setGetItems(productIDandQnt);
  };
  const validFileExtensions = {
    file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };
  const priceRegExp = /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/;
  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }
  const offerSchema = yup.object().shape({
    availableBranch: yup.string(),
    offerName: yup.string().required("required"),
    offerNameAr: yup.string().required("required"),
    offerType: yup.string().required("choose Offer Type"),
    offerPrice: yup
      .string()
      .matches(priceRegExp, "Include decimals")
      .required("required"),
    // offerImg: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
    offerFromDate: yup.string().required("required"),
    offerToDate: yup.string().required("required"),
    offerDescription: yup.string().required("Description required"),
    offerDescriptionAr: yup.string().required("Description in Arabic required"),
  });

  const initialValues = {
    merchant: "",
    availableBranch: "",
    offerName: "",
    offerNameAr: "",
    offerType: "",
    offerPrice: "",
    offerDescription: "",
    offerDescriptionAr: "",
    offerImg: "",
    offerFromDate: "",
    offerToDate: "",
    delivery: delivery,
    vip:vip
  };

  const handledelivery = (event) => {
    setdelivery(!delivery);
  };
  const handleVip = (event) => {
    setVip(!vip);
  };
  const handleSwitchChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const organisationSelectHandler = (newSelectedItems) => {
    const discountsAndOptions = newSelectedItems.map(
      ({ discount, option }) => ({ discount, option })
    );
     console.log(discountsAndOptions,"discountsAndOptions")
    setBuyItems(discountsAndOptions);
  };

  const createNewOffer = (data) => {
    console.log("createNewOffer111", data);
    dispatch(saveNewOffer(data))
      .unwrap()
      .then((res) => {
        console.log("createNewOffer res:",res)
        if(res.status == 200){
          navigate("/offers");
        }
      });
  };

  const updateOfferHandler = (data) => {
    dispatch(updateOffer(data))
      .unwrap()
      .then((res) => {
        console.log("updateOffer res:",res)
        if(res.status == 200){
          navigate("/offers");
        }
        // 
      });
  };
  let timeoutId = null;
  const handleFormSubmit = (values, type) => {
    if (timeoutId) return; // If there's an active timeout, prevent further calls

    timeoutId = setTimeout(() => {
      timeoutId = null; // Reset timeoutId after some delay
    }, 5000); // Prevent multiple submissions within a 1-second window
  
    // values.discountItems = discountItems;
    values.buyItems = buyItems;
    values.getItems = getItems;
    values.vip = vip;
    values.availableBranch = availableBranch;
    values.delivery = delivery;
    values.buyItems = buyItems;
    
    console.log(" OFFER values buyItems", buyItems);
    console.log(" OFFER values", values);
    console.log(" OFFER getItems values", getItems);
    var formData = new FormData();
    formData.append("merchant", values.merchant);
   // formData.append("branches", values.availableBranch);
    formData.append("offerName", values.offerName);
    formData.append("offerNameAr", values.offerNameAr);
    formData.append("offerType", values.offerType);
    formData.append("offerPrice", values.offerPrice);
    formData.append("offerDescription", values.offerDescription);
    formData.append("offerDescriptionAr", values.offerDescriptionAr);
    for (const key of Object.keys(values.availableBranch != null &&values.availableBranch)) {
      formData.append("branches",values.availableBranch != null && values.availableBranch[key]);
    }
    for (const key of Object.keys(values.offerImg != null &&values.offerImg)) {
      formData.append("offerImg",values.offerImg != null && values.offerImg[key]);
    }

    for (const key in values.buyItems) {
      if (values.buyItems.hasOwnProperty(key)) {
        const item = values.buyItems[key];
    
        // If item is an object, append its properties appropriately
        // Assuming item is an object with properties like `product`, `quantity`, etc.
        formData.append("buyList", item); // Append as JSON string
      }
    }

    for (const key in values.getItems) {
      if (values.getItems.hasOwnProperty(key)) {
        const item = values.getItems[key];
    
        // If item is an object, append its properties appropriately
        // Assuming item is an object with properties like `product`, `quantity`, etc.
        formData.append("getList", JSON.stringify(item)); // Append as JSON string
      }
    }
    
    // for (const key of Object.keys(values.buyItems != null &&values.buyItems)) {
    //   formData.append("buyList",values.buyItems != null && values.buyItems[key]);
    // }
    formData.append("offerFromDate", values.offerFromDate);
    formData.append("offerToDate", values.offerToDate);
    formData.append("delivery", values.delivery);
    formData.append("vip", values.vip);
   // formData.append("buyList", values.buyItems);
    
   // formData.append("buyItems",values.buyItems);
    console.log("formDatafinal:", formData);
    if(type == "update"){
      formData.append("offerId", itemToEdit?._id);
      updateOfferHandler(formData);
    }
    else{
      console.log("createcreatecreatecreatecreate")
      createNewOffer(formData);  
    }
  };

  const [value, setValue] = React.useState(0);

  const handleTab = (i) => {
    const a =["Discount", "Combo Package", "BOGO"]
    console.log(a.indexOf(i), "iiiii");
    setValue(a.indexOf(i))
 };
  const handleTabChange = (event, newValue) => {
     console.log(newValue, "newValue");
    setValue(newValue);
  };
  function deleteImageHandler(image) {
    console.log("deleteImageHandler image:",image)
    setSelectedFiles(selectedFiles.filter((e) =>
    //console.log("deleteImageHandler e:",e)
    e != image
    ));
    URL.revokeObjectURL(image);
  }

  const savedValues = {
    offerName: itemToEdit?.offerName,
    offerNameAr: itemToEdit?.offerNameAr,
    offerDescription: itemToEdit?.offerDescription,
    offerFromDate: itemToEdit?.offerFromDate?.toString()?.split('T')[0],
    offerToDate: itemToEdit?.offerToDate?.toString()?.split('T')[0],
    offerType: itemToEdit?.offerType,
    offerDescriptionAr: itemToEdit?.offerDescriptionAr,
    offerPrice:itemToEdit?.offerPrice,
    delivery:itemToEdit?.delivery,
    vip:itemToEdit?.vip,
    offerImage:itemToEdit?.offerImage,
    merchant:itemToEdit?.merchant,
    branches:itemToEdit?.branches,
    


  };

  return (
    <Box m="1rem" pb="0.5rem">
      <Header
        title="ADD NEW OFFER"
        subtitle="Fill in the details to add a new offer"
      />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={
          isEditingOffer.payload.isEditingOffer.isEditing &&
          itemToEdit
            ? savedValues
            :
          initialValues
        }
        validationSchema={offerSchema}
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                control={
                  <YellowSwitch
                    checked={switchState.showOrg}
                    onChange={handleSwitchChange}
                    name="showOrg"
                  />
                }
                label="All Org"
              />
              {!switchState.showOrg && (
                <SearchOraganisation
                  suggestions={allOrganizations}
                  onDiscountItemsChange={organisationSelectHandler}
                />
              )}
            </Box>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                select
                label="Merchant"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  branchHandler(e.target);
                }}
                value={values.merchant}
                name="merchant"
                error={!!touched.merchant && !!errors.merchant}
                helperText={touched.merchant && errors.merchant}
                sx={{ gridColumn: "span 2" }}
              >
                {allMerchantsNameId &&
                  allMerchantsNameId.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.merchName}
                    </MenuItem>
                  ))}
              </TextField>
              <Autocomplete
                multiple
                id="tags-standard"
                options={allBranches}
                value={availableBranch}
                getOptionLabel={(option) => option.merchName}
                filterSelectedOptions
                onChange={(event, newValue) => {
                 console.log("newValue:",newValue.map((val) => val.merchName))
                   setAvailableBranch(newValue.map((val) => val.merchName));
                }}
                renderInput={(params, option) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Offer Avaliable in"
                    placeholder="Branches"
                    value={option}
                  />
                )}
                sx={{ gridColumn: "span 2" }}
              />
              {/* <Autocomplete
                multiple
                id="tags-outlined"
                options={productList.map((option) => option.title)}
                // getOptionLabel={(option) => option.title}
                onChange={(event, value) => {
                  console.log(value, "udhvbfehv");
                  values.availableBranch = value;
                  handleChange(event);
                }}
                // value={values.availableBranch}
                filterSelectedOptions
                sx={{ gridColumn: "span 2" }}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    {...params}
                    label="filterSelectedOptions"
                    placeholder="Favorites"
                  />
                )}
              /> */}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Offer Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.offerName}
                name="offerName"
                error={!!touched.offerName && !!errors.offerName}
                helperText={touched.offerName && errors.offerName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Offer Name (arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.offerNameAr}
                name="offerNameAr"
                error={!!touched.offerNameAr && !!errors.offerNameAr}
                helperText={touched.offerNameAr && errors.offerNameAr}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Offer Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.offerPrice}
                name="offerPrice"
                error={!!touched.offerPrice && !!errors.offerPrice}
                helperText={touched.offerPrice && errors.offerPrice}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                select
                label="Offer Type"
                onBlur={handleBlur}
                value={values.offerType}
                onChange={(e) => {
                  handleTab(e.target.value);
                  handleChange(e);
                }}
                name="offerType"
                error={!!touched.offerType && !!errors.offerType}
                helperText={touched.offerType && errors.offerType}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem key={1} value="Discount">
                  Discount
                </MenuItem>
                <MenuItem key={2} value="BOGO">
                  Buy One Get One
                </MenuItem>
                <MenuItem key={3} value="Combo Package">
                  Combo Deal
                </MenuItem>
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Offer Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.offerDescription}
                multiline
                rows={5}
                name="offerDescription"
                error={!!touched.offerDescription && !!errors.offerDescription}
                helperText={touched.offerDescription && errors.offerDescription}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Offer Description (arabic)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.offerDescriptionAr}
                name="offerDescriptionAr"
                multiline
                rows={5}
                error={
                  !!touched.offerDescriptionAr && !!errors.offerDescriptionAr
                }
                helperText={
                  touched.offerDescriptionAr && errors.offerDescriptionAr
                }
                sx={{ gridColumn: "span 2" }}
              />
              <label htmlFor="file">Upload Offer Image</label>
              <Box
                sx={{
                  gridColumn: "span 4",
                  borderBottom: "1px solid white",
                  marginTop: "-20px",
                }}
              >
                {" "}
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    setFieldValue("offerImg", event.currentTarget.files);
                    if (event.target.files) {
                      const filesArray = Array.from(event.target.files).map(
                        (file) => URL.createObjectURL(file)
                      );

                      setSelectedFiles((prevImages) =>
                        prevImages.concat(filesArray)
                      );
                      Array.from(event.target.files).map(
                        (file) => URL.revokeObjectURL(file) // avoid memory leak
                      );
                    }
                  }}
                />
                {selectedFiles != [] && (
                  <ImgPreview
                    source={selectedFiles}
                    deleteImageHandler={deleteImageHandler}
                  />
                )}
              </Box>

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Offer From"
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                value={values.offerFromDate}
                name="offerFromDate"
                error={!!touched.offerFromDate && !!errors.offerFromDate}
                helperText={touched.offerFromDate && errors.offerFromDate}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Offer To"
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                value={values.offerToDate}
                name="offerToDate"
                error={!!touched.offerToDate && !!errors.offerToDate}
                helperText={touched.offerToDate && errors.offerToDate}
                sx={{ gridColumn: "span 1" }}
              />
              <Box
                sx={{
                  textAlign: "center",
                  gridColumn: "span 2",
                }}
              >
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1.3rem",
                  }}
                >
                  <FormControlLabel
                    control={
                      <YellowSwitch
                        checked={delivery}
                        onChange={handledelivery}
                        name="delivery"
                      />
                    }
                    label="Free Delivery"
                  />
                  <FormControlLabel
                    control={
                      <YellowSwitch
                        checked={vip}
                        onChange={handleVip}
                        name="vip"
                      />
                    }
                    label="vip"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box sx={{ width: "100%", mt: 5 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  // onChange={handleTabChange}
                  aria-label="basic tabs example"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab
                    label="Discount and Delivery Offers"
                    disabled={"Discount" != tabName}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Package and combo offers"
                    disabled={"Combo Package" != tabName}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Buy and Get Offers"
                    disabled={"BOGO" != tabName}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <DiscountDeliveryOffer
                  suggestions={suggestions}
                  onDiscountItemsChange={handleDiscountItemsChange}
                />
                {/* {console.log(discountItems, "discounted from OfferForm")} */}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ gridColumn: "span 4" }}
                  >
                    <PackageOffer
                      suggestions={suggestions}
                      onBuyItemsChange={handleBuyItemsChange}
                    />
                    {/* {console.log(buyItems, " buy  items from OfferForm")} */}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Header subtitle="Add Buy Products " />
                <PackageOffer
                  suggestions={suggestions}
                  onBuyItemsChange={handleBuyItemsChange}
                  // onGetItemsChange={handleGetItemsChange}
                />

                <Header subtitle="Add Get Products " />
                {/* <PackageOffer
                  suggestions={suggestions}
                  onGetItemsChange={handleGetItemsChange}
                /> */}
                <GetOffer
                  suggestions={suggestions}
                  onGetItemsChange={handleGetItemsChange}
                />
                {/* {console.log(getItems, " get  items from OfferForm")} */}
              </TabPanel>
            </Box>
            <Box display="flex" justifyContent="end" m={2}>
            {isEditingOffer.payload.isEditingOffer.isEditing &&
              itemToEdit ? (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                 // disabled={apiStatus === "pending"}
                  onClick={() => handleFormSubmit(values,"update")}
                >
                  Update Offer
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                 // disabled={apiStatus === "pending"}
                  onClick={() => handleFormSubmit(values)}
                >
                  Create Offer
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default OffersForm;

const productList = [
  { title: "Ameliorate Intensive Hand Therapy (75ml)", id: "1" },
  { title: "Ameliorate Smoothing Body Exfoliant 150ml", id: "2" },
  { title: "Ameliorate Transforming Body Cream (225ml)", id: "3" },
  { title: "Ameliorate Exfoliating Body Mitt", id: "4" },
  { title: "Ameliorate Intensive Foot Therapy 75ml", id: "5" },
  { title: "Ameliorate Intensive Hand Therapy Rose (75ml)", id: "6" },
  { title: "City Center", id: "7" },
];
