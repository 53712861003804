import axios from "axios";
import authHeader from "../../services/auth-header";
import {api_ALLMerchantCategories,token} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLMerchantCategories = createAsyncThunk(
  "merchantcategory/getAPI",
  async () => {
    console.log("fetchALLMerchantCategories async:")
    try {
      console.log("fetchALLMerchantCategories try:")
      const response = await axios.post(
        api_ALLMerchantCategories,{"category":"false"},
        { headers: authHeader() }
        //  "http://64.226.124.130:9000/merchants_category"
      );
      return response.data;
    } catch (error) {
      console.log("fetchALLMerchantCategories error:")
      // Throw the error to be handled in the rejected action
     console.log("fetchALLMerchantCategories error:",error)
      // throw error;
    }
   
  }
);

export const saveNewMerchantCategory = createAsyncThunk(
  "merchantcategory/createAPI",
  async (payload) => {
    const response = await axios.post(
      "http://161.97.91.128:9000/merchants_category",
      payload
    );
    console.log(response.data);
    return response.data;
  }
);

export const updateMerchantCategory = createAsyncThunk(
  "merchantcategory/updateAPI",
  async (payload) => {
    const response = await axios.put(
      `http://161.97.91.128:9000/merchants_category/${payload._id}`,
      payload
    );
    return response.data;
  }
);

const initialState = {
  merchantsCategoryData: [],
  loading: "idle",
};

const merchantcategorySlice = createSlice({
  name: "merchantcategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLMerchantCategories.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLMerchantCategories.fulfilled, (state, action) => {
      state.loading = "idle";
      state.merchantsCategoryData = action.payload;
    });
    // builder.addCase(fetchALLProductCategories.fulfilled, (state, action) => {
    //   state.loading = "idle";
    //   state.merchantsCategoryData = action.payload;
    // });
    builder.addCase(saveNewMerchantCategory.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewMerchantCategory.fulfilled, (state, action) => {
      state.loading = "idle";
      state.merchantsCategoryData.unshift(action.payload);
    });

    builder.addCase(updateMerchantCategory.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateMerchantCategory.fulfilled, (state, action) => {
      state.loading = "idle";
      state.merchantsCategoryData = state.merchantsCategoryData.filter(
        (_) => _._id !== action.payload._id
      );
      state.merchantsCategoryData.unshift(action.payload);
    });
  },
});

export const getAllMerchantCategories = (state) =>
  state.merchantcategory.merchantsCategoryData;
export const getLoading = (state) => state.merchantcategory.loading;

export const getMerchantCategoryById = (id) => {
  return (state) =>
    state.merchantcategory.merchantsCategoryData.filter((_) => _.id === id)[0];
};

export default merchantcategorySlice.reducer;
