import axios from "axios";
import authHeader from "../../services/auth-header";

import {api_GetALLMerchants,api_Update_Merchant ,api_Add_Merchant,api_GetALLMerchantsNameId,api_GetALLMerchantBranches} from "../../constants/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchALLMerchants = createAsyncThunk(
  "merchants/getAPI",
  async (i) => {
    console.log("fetchALLMerchantsiiiiii:",i)
    const response = await axios.post(api_GetALLMerchants,
      {
        "pageNumber":i,
        "limit":10
       },
       { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
      );
    console.log("fetchALLMerchants response:", response)
    return response.data;
  }
);

export const fetchALLMerchantsNameId = createAsyncThunk(
  "merchantsNameId/getAPI",
  async (i) => {
   // console.log("fetchALLMerchantsiiiiii:",i)
    const response = await axios.post(api_GetALLMerchantsNameId,
      {
       
       },
       { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
      );
    console.log("fetchALLMerchantsNameId response:", response)
    return response.data;
  }
);

export const fetchALLBranches = createAsyncThunk(
  "merchantBranches/getAPI",
  async (i) => {
   // console.log("fetchALLMerchantsiiiiii:",i)
    const response = await axios.post(api_GetALLMerchantBranches,
      {
        "merchantid":i
       },
       { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
      );
    console.log("fetchALLBranches response:", response)
    return response.data;
  }
);

export const saveNewMerchant = createAsyncThunk(
  "merchants/createAPI",
  async (payload) => {
    const response = await axios.post(api_Add_Merchant,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    console.log("saveNewMerchant response:", response)
    return response.data;
  }
);

export const updateMerchant = createAsyncThunk(
  "merchants/updateAPI",
  async (payload) => {
    console.log("updateMerchant::",payload, "Payload for reducer");
    const response = await axios.patch(api_Update_Merchant,
      payload,
      { headers: authHeader() }
      // {
      //   headers: {
      //     "x-access-token": token
      //   },
      // }
    );
    console.log("update Merchant response:", response)
    return response.data;
  }
);

const initialState = {
  merchantsData: [],
  merchantsDataNameId: [],
  merchantBranches:[],
  loading: "idle",
};

const merchantslice = createSlice({
  name: "merchants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALLMerchants.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchALLMerchants.fulfilled, (state, action) => {
      state.loading = "idle";
      state.merchantsData = action.payload;
    });
    builder.addCase(fetchALLMerchantsNameId.pending, fetchAndNameIdPendingHandler);
    builder.addCase(fetchALLMerchantsNameId.fulfilled, fetchNameIdFulfilledHandler);
    builder.addCase(fetchALLBranches.pending, fetchALLBranchesPendingHandler);
    builder.addCase(fetchALLBranches.fulfilled, fetchALLBranchesFulfilledHandler);
    
    builder.addCase(saveNewMerchant.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(saveNewMerchant.fulfilled, (state, action) => {
      state.loading = "idle";
      //state.merchantsData.unshift(action.payload);
      state.merchantsData = action.payload;
    });

    builder.addCase(updateMerchant.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateMerchant.fulfilled, (state, action) => {
      state.loading = "idle";
      state.merchantsData = state.merchantsData.data.filter(
        (_) => _.id !== action.payload.id
      );
      state.merchantsData.unshift(action.payload);
    });
  },
});

const fetchALLBranchesPendingHandler = (state, action) => {
  state.loading = "pending";
};
const fetchALLBranchesFulfilledHandler = (state, action) => {
  state.loading = "idle";
  state.merchantBranches = action.payload;
};
const fetchAndNameIdPendingHandler = (state, action) => {
  state.loading = "pending";
};
const fetchNameIdFulfilledHandler = (state, action) => {
  state.loading = "idle";
  state.merchantsDataNameId = action.payload;
};
// export const getAllMerchants = (state) => state.merchant.merchantsData;
export const getAllMerchants = () => {
  return (state) =>
//console.log("state.merchant.merchantsData::::",state.merchant)
state.merchant.merchantsData.data      
//state.merchant.merchantsData.filter((_) => _.type === "merchant");
};
export const getTotalNoMerchants = () => {
  return (state) =>
//console.log("state.merchant.merchantsData::::",state.merchant)
state.merchant.merchantsData.total      
//state.merchant.merchantsData.filter((_) => _.type === "merchant");
};
export const getAllMerchantsBranches = () => {
  return (state) =>
//console.log("state.merchant.merchantsData::::",state.merchant)
state.merchant.merchantBranches      
//state.merchant.merchantsData.filter((_) => _.type === "merchant");
};
export const getAllMerchantsNameId = () => {
  return (state) =>
//console.log("state.merchant.merchantsData::::",state.merchant)
state.merchant.merchantsDataNameId      
//state.merchant.merchantsData.filter((_) => _.type === "merchant");
};
export const getLoading = (state) => state.merchant.loading;

export const getMerchantById = (id) => {
  return (state) => state.merchant.merchantsData.data && state.merchant.merchantsData.data.filter((_) => _._id === id)[0];
};

export const getMerchantType = () => {
  return (state) =>
  state.merchant.merchantsData != [] && state.merchant.merchantsData.data.filter((_) => _.type === "branch");
};
// console.log(getMerchantType("645d5d43e70515bc1bf8da0e"),"reducer")
export default merchantslice.reducer;
