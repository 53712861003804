//BASE URL
export const baseUrl = "http://161.97.91.128/api/go";
//export const baseUrl = "http://localhost:9001/api/go";
//export const baseUrl = "https://golalitatwffer.com/api/go";

export const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3MTdiZTk2MTdlMmFjOWZhMmZiNjk3OCIsImRldmljZV90eXBlIjoiaW9zIiwidXNlclR5cGUiOiJjdXN0b21lciIsImlhdCI6MTczMDI3MjkzNywiZXhwIjoxNzMwMzU5MzM3fQ.-43bO5S6QplImSZh5cmz10Ea8FQ3hVYovE2IZxh0bck"
//AUTH
export const api_AddOrganisation = `${baseUrl}/add_organisation`;
export const api_GetAllOrganisation = `${baseUrl}/get_organisations`;
export const api_UpdateOrganisation = `${baseUrl}/update_organisation`;


//USER
export const api_get_userdetails = `${baseUrl}/get_user`;


//USERS
export const api_get_all_user = `${baseUrl}/get_all_user`;



//REGISTERATION CODES
export const api_CreateRegisterationCodes = `${baseUrl}/generatecodes`;



//MERCHANTS
export const api_ALLMerchantCategories = `${baseUrl}/merchants_category`;
export const api_GetALLMerchants = `${baseUrl}/merchants`;
export const api_GetALLMerchantsNameId = `${baseUrl}/get_merchant_name`;

export const api_Add_Merchant = `${baseUrl}/add_merchant`;
export const api_Update_Merchant = `${baseUrl}/update_merchant`;
export const api_GetALLMerchantBranches = `${baseUrl}/branchs_merchant`;




//OFFERS
export const api_GetALLOffers = `${baseUrl}/get_offers`;
export const api_Add_Offers = `${baseUrl}/add_offers`;
export const api_Update_Offers = `${baseUrl}/update_offers`;


//PRODUCTS
export const api_GetALLProducts = `${baseUrl}/get_products`;
export const api_AddProducts = `${baseUrl}/add_products`;
export const api_UpdateProducts= `${baseUrl}/products/`;
export const api_AllProducts_category= `${baseUrl}/products_category`;


//PRODUCTS CATEGORY
export const api_AddProductCategory = `${baseUrl}/add_products_category`;
export const api_UpdateProductCategory = `${baseUrl}/update_products_category`;


//FEEDBACKS
export const api_GetAppFeedbacks = `${baseUrl}/get_app_feedback`;


//NOTIFICATIONS
export const api_GetNotifications = `${baseUrl}/get_mobile_notification`;
export const api_AddNotifications = `${baseUrl}/add_mobile_notification`;
export const api_PushNotifications = `${baseUrl}/push_notification`;


//LOYALITYPOINTS
export const api_send_loyalty_point = `${baseUrl}/send_loyalty_point`;
export const api_get_loyalty_sent = `${baseUrl}/get_loyalty_sent`;
export const api_get_loyalty_recived = `${baseUrl}/get_loyalty_recived`;
