import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Collapse,
  Divider,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  // SettingsOutlined,
} from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "../components/FlexBetween";
import AddIcon from "@mui/icons-material/Add";
import logo from "../assets/mzayaLogo.png";

import { useDispatch, useSelector } from "react-redux";
import { getUserDetails
  
} from "../features/users/userSlice";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import InboxIcon from "@mui/icons-material/MoveToInbox";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Manage",
    icon: null,
  },
  {
    text: "Products",
    icon: <ShoppingCartOutlined />,
    subNavItems: [
      { text: "Add Product", icon: <AddIcon /> },
      { text: "Add Product Category", icon: <AddIcon /> },
    ],
  },
  {
    text: "Offers",
    icon: <LocalOfferIcon />,
    subNavItems: [{ text: "Add Offer", icon: <AddIcon /> }],
  },
  {
    text: "Merchants",
    icon: <Groups2Outlined />,
    subNavItems: [
      { text: "Add Merchant", icon: <AddIcon /> },
      { text: "Add Merchant Category", icon: <AddIcon /> },
    ],
  },
  // {
  //   text: "Branches",
  //   icon: <StorefrontOutlinedIcon />,
  //   subNavItems: [
  //     { text: "Add Merchant", icon: <AddIcon /> },
  //     { text: "Add Merchant Category", icon: <AddIcon /> },
  //   ],
  // },
  {
    text: "Organizations",
    icon: <Groups2Outlined />,
    subNavItems: [{ text: "Add Organization", icon: <AddIcon /> }],
  },
  // {
  //   text: "Orders",
  //   icon: <RestaurantMenuIcon />,
  //   // subNavItems: [{ text: "Add Organization", icon: <AddIcon /> }],
  // },
  {
    text: "Users",
    icon: <PeopleOutlineOutlinedIcon />,
    subNavItems: [{ text: "Add User", icon: <AddIcon /> }],
  },
  {
    text: "Feedbacks",
    icon: <PeopleOutlineOutlinedIcon />
  },
  {
    text: "Loyality Points",
    icon: <QrCodeScannerIcon />,
  },
  // {
  //   text: "Invoices",
  //   icon: <ReceiptIcon />,
  //   // subNavItems: [{ text: "Add User", icon: <AddIcon /> }],
  // },
  {
    text: "Profile",
    icon: <PublicOutlined />,
  },
  {
    text: "Notifications",
    icon: <NotificationsOutlinedIcon />,
  },
  {
    text: "Registration Codes",
    icon: <QrCodeScannerIcon />,
  },
  // {
  //   text: "Sales",
  //   icon: null,
  // },
  // {
  //   text: "Overview",
  //   icon: <PointOfSaleOutlined />,
  // },
  // {
  //   text: "Daily",
  //   icon: <TodayOutlined />,
  // },
  // {
  //   text: "Monthly",
  //   icon: <CalendarMonthOutlined />,
  // },
  // {
  //   text: "Breakdown",
  //   icon: <PieChartOutlined />,
  // },
  // {
  //   text: "Management",
  //   icon: null,
  // },
  // // {
  // //   text: "Admin",
  // //   icon: <AdminPanelSettingsOutlined />,
  // // },
  // {
  //   text: "Performance",
  //   icon: <TrendingUpOutlined />,
  // },
];

const filterednavItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Offers",
    icon: <LocalOfferIcon />,
    subNavItems: [{ text: "Add Offer", icon: <AddIcon /> }],
  },
  {
    text: "Merchants",
    icon: <Groups2Outlined />,
    subNavItems: [
      { text: "Add Merchant", icon: <AddIcon /> },
      { text: "Add Merchant Category", icon: <AddIcon /> },
    ],
  },
  // {
  //   text: "Branches",
  //   icon: <StorefrontOutlinedIcon />,
  //   subNavItems: [
  //     { text: "Add Merchant", icon: <AddIcon /> },
  //     { text: "Add Merchant Category", icon: <AddIcon /> },
  //   ],
  // },
  {
    text: "Organizations",
    icon: <Groups2Outlined />,
    subNavItems: [{ text: "Add Organization", icon: <AddIcon /> }],
  }
];

// {navItems.map(item => console.log(item.subNavItems))}

const user = JSON.parse(localStorage.getItem("user"));
const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
console.log("user:::******************",user)

const userDetails = useSelector(getUserDetails);
console.log("sidebar############################ userDetails:",userDetails)
const fnavItems = userDetails.email == "dev@gmail.com" ? filterednavItems : navItems ;
  // useEffect(() => {
  //   setActive(pathname.substring(1));
  // }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            // overflow: "hidden",
            overflowX: "hidden",
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img src={logo} alt="Mzaya Premium Loyalty Program" />
                  <Typography variant="h4" fontWeight="bold">
                    GoLalita{" "}
                    <Typography variant="caption" display="block" gutterBottom>
                      Perimium
                    </Typography>
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {fnavItems.map(({ text, icon, path }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase().replace(/\s+/g, "");

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        // navigate(path)
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[400]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[400],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
