import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Autocomplete,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import YellowSwitch from "../../components/YellowSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getProductById,
  saveNewProduct,
  updateProduct,
} from "../../features/products/productSlice";
import { useNavigate } from "react-router-dom";
import ImgPreview from "../../components/ImgPreview";
import { useParams } from "react-router-dom";
import { setIsEditing } from "../../features/products/editSlice";
import {
  fetchALLMerchants,
  getAllMerchants,
 // getMerchantType,
 fetchALLBranches,
 getAllMerchantsBranches,getAllMerchantsNameId,fetchALLMerchantsNameId
} from "../../features/merchants/merchantSlice";
import {
  fetchALLProductCategories,
  getAllProductCategories,
} from "../../features/products/productCategorySlice";

// const priceRegExp = /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/;
const validFileExtensions = {
  file: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};
// function isValidFileType(fileName, fileType) {
//   return (
//     fileName &&
//     validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
//   );
// }

const ProductForm = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [availableBranch, setAvailableBranch] = useState([]);
  const [delivery, setdelivery] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiStatus = useSelector(getLoading);
  const params = useParams();
  const itemToEdit = useSelector(getProductById(params.id));
  const isEditing = useSelector(setIsEditing);
  const allMerchants = useSelector(getAllMerchants());
  const allMerchantsNameId = useSelector(getAllMerchantsNameId());
  const allBranches = useSelector(getAllMerchantsBranches());
  const allProductCategories = useSelector(getAllProductCategories);
console.log("ProductForm params:",params)

console.log("ProductForm itemToEdit:",itemToEdit)
  const savedValues = {
    productName: itemToEdit?.productName,
    merchantId: itemToEdit?.merchantId,
    productNameAr: itemToEdit?.productNameAr,
    productCategory: itemToEdit?.productCategory,
    productType: itemToEdit?.productType,
    productPrice: itemToEdit?.productPrice,
    productDescription: itemToEdit?.productDescription,
    productDescriptionAr: itemToEdit?.productDescriptionAr,
    imgCollection: itemToEdit?.imgCollection,
    delay_return: itemToEdit?.delay_return,
    approved: itemToEdit?.approved,
    delivery: itemToEdit?.delivery,
    _id: params.id,
  };

  console.log(allBranches, "allBranches");
  console.log(allMerchants, "allMerchants");
   console.log(allProductCategories, "allProductCategories");

  const handledelivery = (event) => {
    setdelivery(!delivery);
  };

  function deleteImageHandler(image) {
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const productSchema = yup.object().shape({
    productName: yup.string().required("required"),
    productNameAr: yup.string().required("required"),
    productCategory: yup.string().required("required"),
    productType: yup.string().required("choose Product Type"),
    // productPrice: yup
    //   .number()
    //   .required("required"),
    productDescription: yup.string().required("Description required"),
    productDescriptionAr: yup
      .string()
      .required("Description in Arabic required"),
    // productImg: yup
    //   .mixed()
    //   .required()
    //   .test("is-valid-type", "Not a valid image type", (value) =>
    //     isValidFileType(value && value.name.toLowerCase(), "file")
    //   ),
    delay_return: yup.number().required("required"),
  });

  const initialValues = {
    merchant: "",
    productName: "",
    productNameAr: "",
    productCategory: "",
    productType: "",
    productPrice: "",
    productDescription: "",
    productDescriptionAr: "",
    imgCollection: null,
    delay_return: "",
    approved: true,
    delivery: delivery,
  };

  const createNewProduct = (data) => {
    dispatch(saveNewProduct(data))
      .unwrap()
      .then((res) => {
        console.log("createNewProduct res:",res)
        if(res._id){
          navigate("/products");
        }
        // navigate("/products");
      });
  };

  const updateNewProduct = (data) => {
    dispatch(updateProduct(data))
      .unwrap()
      .then(() => {
        // navigate("/products");
      });
  };
  // console.log(selectedFiles);
  const handleFormSubmit = (values) => {
    values.imgCollection = selectedFiles;
    values.delivery = delivery;
    console.log(values, "values");
    var formData = new FormData();
    for (const key of Object.keys(values.imgCollection != null && values.imgCollection)) {
      formData.append("productImg",values.imgCollection != null && values.imgCollection[key]);
    }
    formData.append("merchantId", values.merchantId);
    formData.append("productName", values.productName);
    formData.append("productNameAr", values.productNameAr);
    formData.append("productCategory", values.productCategory);
    formData.append("productType", values.productType);
    formData.append("productDescription", values.productDescription);
    formData.append("productDescriptionAr", values.productDescriptionAr);
    formData.append("delay_return", values.delay_return);
    formData.append("productPrice", values.productPrice);
    formData.append("approved", values.approved);
    // availableBranch.map((branch) => formData.append("availableBranch", branch));
    formData.append("availableBranch", availableBranch);
    formData.append("delivery", delivery);

    createNewProduct(formData);
   // updateNewProduct(formData);
    dispatch(setIsEditing(false));
    console.log(params.id, "params id");
  };

  useEffect(() => {
    // dispatch(fetchALLOrganizations());
    // dispatch(fetchALLProducts());
     dispatch(fetchALLMerchantsNameId());
    dispatch(fetchALLProductCategories());
    dispatch(fetchALLMerchants());
  }, [dispatch]);

  const branchHandler = (newSelectedItems) => {
    console.log("branchHandler",newSelectedItems)
    dispatch(fetchALLBranches(newSelectedItems.value));
  };

  return (
    <Box m="1rem" pb="0.5rem">
      <Header
        title="ADD NEW PRODUCT"
        subtitle="Fill in the details to add a new product"
      />
      {/* {console.log("Item to Edit", itemToEdit)} */}
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={
          isEditing.payload.isEditing.isEditing && itemToEdit
            ? savedValues
            : initialValues
        }
        // initialValues={initialValues}
        validationSchema={productSchema}
        // enableReinitialize
        style={{ marginTop: "20px" }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Merchant"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    branchHandler(e.target);
                  }}
                  value={values.merchantId}
                  name="merchantId"
                  error={!!touched.merchantId && !!errors.merchantId}
                  helperText={touched.merchantId && errors.merchantId}
                  sx={{ gridColumn: "span 2" }}
                >
                  {allMerchantsNameId?.map((merchant) => (
                    <MenuItem key={merchant._id} value={merchant._id}>
                      {merchant.merchName}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={allBranches}
                  getOptionLabel={(option) => option.merchName}
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    setAvailableBranch(newValue.map((val) => val.merchName));
                  }}
                  renderInput={(params, option) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Product Avaliable in"
                      placeholder="Branches"
                      value={option}
                    />
                  )}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Product Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productName}
                  name="productName"
                  error={!!touched.productName && !!errors.productName}
                  helperText={touched.productName && errors.productName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Product Name (arabic)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productNameAr}
                  name="productNameAr"
                  error={!!touched.productNameAr && !!errors.productNameAr}
                  helperText={touched.productNameAr && errors.productNameAr}
                  sx={{ gridColumn: "span 2" }}
                />
                {/* <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Category"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productCategory}
                  name="productCategory"
                  error={!!touched.productCategory && !!errors.productCategory}
                  helperText={touched.productCategory && errors.productCategory}
                  sx={{ gridColumn: "span 2" }}
                /> */}
                <TextField
                  fullWidth
                  variant="filled"
                  select
                  type="text"
                  label="Category"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.productCategory}
                  name="productCategory"
                  error={!!touched.productCategory && !!errors.productCategory}
                  helperText={touched.productCategory && errors.productCategory}
                  sx={{ gridColumn: "span 2" }}
                >
                  {allProductCategories?.map((productCat) => (
                    <MenuItem
                      key={productCat._id}
                      value={productCat.categoryName}
                    >
                      {productCat.categoryName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productPrice}
                  name="productPrice"
                  error={!!touched.productPrice && !!errors.productPrice}
                  helperText={touched.productPrice && errors.productPrice}
                  sx={{
                    gridColumn: "span 2",
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  }}
                ></TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  value={values.productType}
                  select // tell TextField to render select
                  label="Product Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="productType"
                  defaultValue=""
                  error={!!touched.productType && !!errors.productType}
                  helperText={touched.productType && errors.productType}
                  sx={{ gridColumn: "span 2" }}
                >
                  <MenuItem key={1} value="Service">
                    Service
                  </MenuItem>
                  <MenuItem key={2} value="Product">
                    Product
                  </MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  value={values.delay_return}
                  select // tell TextField to render select
                  label="Return Within (days)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="delay_return"
                  defaultValue=""
                  error={!!touched.delay_return && !!errors.delay_return}
                  helperText={touched.delay_return && errors.delay_return}
                  sx={{ gridColumn: "span 2" }}
                >
                  <MenuItem key={1} value="false">
                    We dont accept returns
                  </MenuItem>
                  <MenuItem key={2} value={1}>
                    1 day
                  </MenuItem>
                  <MenuItem key={3} value={2}>
                    2 days
                  </MenuItem>
                  <MenuItem key={4} value={3}>
                    3 days
                  </MenuItem>
                  <MenuItem key={5} value={4}>
                    4 days
                  </MenuItem>
                  <MenuItem key={6} value={5}>
                    5 days
                  </MenuItem>
                  <MenuItem key={7} value={6}>
                    6 days
                  </MenuItem>
                  <MenuItem key={8} value={7}>
                    7 days
                  </MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Product Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productDescription}
                  multiline
                  rows={5}
                  name="productDescription"
                  error={
                    !!touched.productDescription && !!errors.productDescription
                  }
                  helperText={
                    touched.productDescription && errors.productDescription
                  }
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Product Description (arabic)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.productDescriptionAr}
                  name="productDescriptionAr"
                  multiline
                  rows={5}
                  error={
                    !!touched.productDescriptionAr &&
                    !!errors.productDescriptionAr
                  }
                  helperText={
                    touched.productDescriptionAr && errors.productDescriptionAr
                  }
                  sx={{ gridColumn: "span 4" }}
                />
                <label htmlFor="file">Upload Product Image</label>

                <Box
                  sx={{
                    gridColumn: "span 4",
                    borderBottom: "1px solid white",
                    marginTop: "-20px",
                  }}
                >
                  {" "}
                  <input
                    type="file"
                    multiple
                    onChange={(event) => {
                      setFieldValue("imgCollection", event.currentTarget.files);
                      if (event.target.files) {
                        const filesArray = Array.from(event.target.files).map(
                          (file) => URL.createObjectURL(file)
                        );

                        setSelectedFiles((prevImages) =>
                          prevImages.concat(filesArray)
                        );
                        Array.from(event.target.files).map(
                          (file) => URL.revokeObjectURL(file) // avoid memory leak
                        );
                      }
                    }}
                  />
                  {selectedFiles != [] && (
                    <ImgPreview
                      source={selectedFiles}
                      deleteImageHandler={deleteImageHandler}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    textAlign: "center",
                    gridColumn: "span 4",
                  }}
                >
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      sx={{
                        color: theme.palette.secondary[500],
                      }}
                      component="legend"
                    >
                      Actions
                    </FormLabel>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "1.3rem",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <YellowSwitch
                            checked={delivery}
                            onChange={handledelivery}
                            name="delivery"
                          />
                        }
                        label="Delivery"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" m={2}>
                {/* <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={apiStatus === "pending"}
                  onClick={() => updateNewProduct(values)}
                >
                  {apiStatus === "pending"
                    ? "Updating Product........."
                    : "Update Product"}
                </Button> */}

                
                {isEditing.payload.isEditing.isEditing  ? (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={apiStatus === "pending"}
                    onClick={() =>updateNewProduct(values)}
                  >
                    {apiStatus === "pending"
                      ? "Updating Product........."
                      : "Update Product"}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                   // disabled={apiStatus === "pending"}
                    onClick={() => handleFormSubmit(values)}
                  >
                    {apiStatus === "pending"
                      ? "Adding Product........."
                      : "Add Product"}
                  </Button>
                )}
                {/* { !itemToEdit && (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={apiStatus === "pending"}
                    onClick={() => handleFormSubmit(values)}
                  >
                    {apiStatus === "pending"
                      ? "Adding Product........."
                      : "Add Product"}
                  </Button>
                )} */}
              </Box>
            </>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProductForm;
